@import './helpers/variables.scss';
@import './helpers/mixins.scss';

html {
  height: 100% !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  height: inherit;
  background-color: map-get($colors, 'background');
  font-size: 16px;
  font-weight: 400;
  line-height: 1.24;
  font-family: 'Nunito', sans-serif;

  overflow: auto;
  overflow-x: hidden;
}

#root {
  height: inherit;

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    height: inherit;
  }

  #terms-of-use main,
  #privacy-policy main,
  #accessibility-statement main {
    padding: 70px 0 0;
  }

  main {
    position: relative;
    flex: 1 0 auto;
    padding: 125px 0 0;
    transition: all 0.3s ease;

    @media screen and (max-width: $tablet-sm) {
      padding: 75px 0 0;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  a {
    text-decoration: none;
  }

  textarea {
    resize: none;
  }

  section {
    margin: 120px 0 0;

    @media screen and (max-width: $tablet-sm) {
      margin: 60px 0 0;
    }
  }

  strong,
  b {
    font-weight: bold;
  }
}

.logo {
  display: block;
  height: 50px;
  max-width: 140px;

  img {
    object-fit: contain;
    height: inherit;
    width: 100%;
  }
}

.yellow-btn {
  padding: 15px 20px;
  background-color: map-get($colors, 'yellow');
  color: map-get($colors, 'text');
  border: none;
  border-radius: 30px;

  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;

  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: map-get($colors, 'green');
    color: #fff;
  }
}
