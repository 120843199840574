@import '../../styles/helpers/index.scss';

.modal {
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;

    z-index: 999;

    width: 100%;
    height: 100%;
  }

  &-background {
    height: inherit;
    width: inherit;
    background-color: rgba(#000, 0.5);
  }

  &-inner {
    padding: 60px 40px;
    position: absolute;
    width: 480px;
    height: 580px;
    background: #f2f2f2;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    @media screen and (max-width: $tablet-sm) {
      width: 90%;
    }

    .title {
      font-size: 40px;
      line-height: 24px;
      color: map-get($colors, 'text');
    }

    form {
      margin: 35px 0 0;

      display: flex;
      flex-direction: column;
      gap: 20px;

      input,
      select {
        &:not([type='submit']) {
          background: #fff;
          border: 1px solid #999;
          border-radius: 40px;
          height: 45px;
          padding: 0 15px;

          font-family: 'Nunito', sans-serif;
          font-size: 16px;
          line-height: 14px;

          &::placeholder {
            font-family: inherit;
            color: #999;
          }
        }
      }

      input[type='submit'] {
        margin: 20px 0 0;
        max-width: 130px;
        border: none !important;
        background-color: map-get($colors, 'yellow');
      }
    }
  }
}

.close-btn {
  width: 40px;
  height: 40px;

  background-color: #fff;
  border-radius: 50%;

  position: absolute;
  right: 20px;
  top: 20px;

  cursor: pointer;

  @include align-center;
  justify-content: center;

  &::after {
    content: '\00d7';
    font-size: 30px;
    color: map-get($colors, 'green');

    transition: all 0.3s ease;
  }

  &:hover {
    &::after {
      transform: rotate(360deg);
    }
  }
}
