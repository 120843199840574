@import '../../styles/helpers/index.scss';

.course {
  .section-title {
    max-width: 830px;
  }

  &__timeline {
    margin: 50px 0 0;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 60px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      gap: 30px;
    }

    &--item {
      position: relative;
      max-width: 40%;
      width: 100%;

      max-height: 450px;
      height: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
        margin-top: 0 !important;

        &:not(:first-of-type) {
          border-top: 1px dashed #999;
          padding-top: 30px;
        }
      }

      &::before {
        @media screen and (max-width: $tablet-sm) {
          display: none;
        }
      }

      &:nth-of-type(1) {
        &::before {
          display: none;
        }
      }

      &:nth-of-type(2) {
        margin-top: 25%;
      }

      &:nth-of-type(3) {
        margin-top: -13%;

        &::before {
          content: '';

          border: 4px dashed map-get($colors, 'green');

          width: 200px;

          position: absolute;
          left: auto !important;
          right: -150px;
          top: 50px !important;
          transform: rotate(-60deg) !important;
        }
      }

      &:nth-of-type(4) {
        margin-top: 12%;
      }

      &:nth-of-type(1n) {
        &::before {
          content: '';

          border: 4px dashed map-get($colors, 'green');

          width: 200px;

          position: absolute;
          left: -300px;
          top: 40px;
          transform: rotate(60deg);
        }
      }

      &:nth-of-type(2n) {
        .icon {
          right: auto;
          left: -180px !important;

          @media screen and (max-width: $tablet-sm) {
            left: auto !important;
            right: 0;
          }
        }
      }

      .icon {
        position: absolute;
        top: 50%;
        z-index: 99;

        right: -40px;
        left: auto;

        transform: translateY(-50%);

        width: 160px;
        height: 160px;

        border: 4px solid map-get($colors, 'green');
        border-radius: 50%;

        background-color: #fff;

        @include align-center;
        justify-content: center;

        img {
          height: 80px;
          width: 80px;

          @media screen and (max-width: $tablet-sm) {
            height: 50px;
            width: 50px;
          }
        }

        @media screen and (max-width: $tablet-sm) {
          top: 30px;
          right: 0;
          transform: none;

          width: 80px;
          height: 80px;
        }
      }

      .stage {
        padding: 6px 14px;
        display: inline-block;
        background: #ffffff;

        border: 4px solid #43b5a0;
        border-radius: 100px;

        font-weight: 700;
        font-size: 30px;
        line-height: 120%;
        color: map-get($colors, 'green');
      }

      .title {
        margin: 20px 0 0;
        font-weight: 700;
        font-size: 40px;
        line-height: 110%;
      }

      .salary {
        margin: 20px 0 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 110%;
        color: map-get($colors, 'green');
      }

      .description {
        margin: 25px 0 0;
        padding: 25px 140px 0 0;
        font-size: 18px;
        line-height: 150%;

        border-top: 4px solid map-get($colors, 'green');

        @media screen and (max-width: $tablet-sm) {
          padding: 25px 0 0;
        }
      }

      .yellow-btn {
        margin: 20px 0 0;
      }
    }
  }
}
