@import '../../styles/helpers/index.scss';

.hero {
  margin: 0 !important;

  &__inner {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      gap: 20px;
    }
  }

  &__content {
    padding: 80px 40px 70px;
    border-radius: 40px;
    background-color: map-get($colors, 'green');
    color: #fff;

    @media screen and (max-width: $tablet-sm) {
      padding: 50px 20px;
      text-align: center;
    }

    h1 {
      display: inline-block;
      padding: 0 12px;
      font-weight: 700;
      font-size: 66px;
      line-height: 120%;

      @media screen and (max-width: $tablet-sm) {
        font-size: 36px;
      }
    }

    & > p {
      padding: 0 12px;
      max-width: 600px;
      font-weight: 700;
      font-size: 66px;
      line-height: 120%;

      @media screen and (max-width: $tablet-sm) {
        font-size: 32px;
        padding: 10px 15px 0;
      }
    }

    & > div {
      margin: 20px 0 0;
      padding: 0 12px;
      font-size: 24px;
      line-height: 150%;

      @media screen and (max-width: $tablet-sm) {
        font-size: 16px;
        padding: 0 15px;
      }
    }

    .yellow-btn {
      margin: 70px 0 0 12px;
      border: 1px solid transparent;

      @media screen and (max-width: $tablet-sm) {
        margin: 30px 0 0;
      }

      &:hover {
        border-color: #fff;
      }
    }
  }

  &__image {
    background-color: map-get($colors, 'green');
    background-image: url('../../static/hero_image.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    min-height: 610px;
    height: 100%;
    max-width: 32%;
    width: 100%;

    border-radius: 40px;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }

  .mobile-image {
    display: none;

    @media screen and (max-width: $tablet-sm) {
      display: block;

      background-color: map-get($colors, 'green');
      background-image: url('../../static/hero_image.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      min-height: 300px;
    }
  }
}
