@import '../../styles/helpers/index.scss';

.page-footer {
  padding: 55px 0 20px;
  background-color: map-get($colors, 'green');

  color: #fff;

  a {
    color: #fff;
  }

  &__inner {
    margin: 30px 0 0;

    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      gap: 30px;
    }

    & > ul {
      display: flex;
      flex-direction: column;
      gap: 25px;

      font-size: 16px;
      line-height: 155%;

      li {
        position: relative;
        padding-left: 30px;

        &::before {
          content: '';

          position: absolute;
          left: 0;
          top: 50%;

          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;

          transform: translateY(-50%);
        }

        &.address {
          max-width: 230px;

          &::before {
            width: 13px;
            height: 18px;

            background-image: url('../../static/map-icon.svg');
          }
        }

        &.phone {
          font-weight: bold;

          &::before {
            width: 18px;
            height: 18px;

            background-image: url('../../static/phone-icon-white.svg');
          }
        }

        &.email {
          &::before {
            width: 18px;
            height: 13px;

            background-image: url('../../static/mail-icon.svg');
          }
        }
      }
    }

    nav {
      ul {
        li {
          font-weight: 700;
          font-size: 16px;
          line-height: 250%;
          text-transform: uppercase;

          a {
            cursor: pointer;
          }
        }
      }
    }

    div {
      .hours {
        & > p {
          font-weight: 700;
          font-size: 16px;
          line-height: 180%;
          text-transform: uppercase;
        }

        span {
          margin: 2px 0 0;
          display: block;
          font-size: 16px;
          line-height: 155%;
        }
      }

      .social-networks {
        margin: 35px 0 0;
        @include align-center;
        gap: 30px;

        span {
          font-weight: 700;
          font-size: 16px;
          line-height: 155%;
          text-transform: uppercase;
        }

        ul {
          @include align-center;
          gap: 15px;
        }

        a {
          display: block;
          width: 40px;
          height: 40px;
          @include align-center;
          justify-content: center;

          border: 1px solid #fff;
          border-radius: 50%;

          transition: all 0.3s ease;

          &:hover {
            background-color: #fff;
            color: map-get($colors, 'green');
          }
        }
      }
    }
  }

  &__underline {
    margin: 60px 0 0;
    padding: 20px 0 0;

    border-top: 1px solid #fff;

    @include align-center;
    justify-content: space-between;

    font-size: 12px;
    line-height: 180%;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
      gap: 15px;
      padding: 20px 0 60px;
    }
  }
}
