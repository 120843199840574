@import '../../styles/helpers/index.scss';

.about-us {
  &__inner {
    margin: 60px 0 0;
    display: flex;
    gap: 20px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    & > div {
      max-width: 50%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
        width: auto;
      }
    }

    .content {
      @media screen and (max-width: $tablet-sm) {
        order: 2;
      }
      p {
        color: map-get($colors, 'text');
        font-size: 18px;
        line-height: 150%;

        @media screen and (max-width: $tablet-sm) {
          font-size: 16px;
        }

        &:not(:last-of-type) {
          margin: 0 0 15px;
        }
      }
    }

    .image {
      background-image: url('../../static/about-us_background.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      min-height: 530px;

      @media screen and (max-width: $tablet-sm) {
        min-height: 300px;
      }
    }
  }
}
