@import '../../styles/helpers/index.scss';

.section-title {
  .subtitle {
    margin: 0 0 15px;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-transform: uppercase;

    color: map-get($colors, 'green');
  }

  img {
    object-fit: cover;
    user-select: none;
  }

  .title {
    margin: 15px 0 0;
    font-weight: 700;
    font-size: 56px;
    line-height: 120%;

    color: map-get($colors, 'text');

    @media screen and (max-width: $tablet-sm) {
      font-size: 32px;
    }
  }
}
