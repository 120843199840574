@import '../../styles/helpers/index.scss';

.gallery {
  &__inner {
    margin: 60px 0 0;
    @include grid($columns: repeat(6, 1fr), $rows: repeat(6, 1fr));

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }

    & > div {
      @media screen and (max-width: $tablet-sm) {
        grid-area: unset !important;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        margin: 0 auto;
        border-radius: 40px;
      }

      &:nth-of-type(1) {
        grid-area: 1 / 1 / 2 / 4;
      }

      &:nth-of-type(2) {
        grid-area: 1 / 4 / 2 / 7;
      }

      &:nth-of-type(3) {
        grid-area: 2 / 1 / 4 / 5;
      }

      &:nth-of-type(4) {
        grid-area: 2 / 5 / 3 / 7;
      }

      &:nth-of-type(5) {
        grid-area: 3 / 5 / 4 / 7;
      }

      &:nth-of-type(6) {
        grid-area: 4 / 1 / 5 / 3;
      }

      &:nth-of-type(7) {
        grid-area: 5 / 1 / 6 / 3;
      }

      &:nth-of-type(8) {
        grid-area: 4 / 3 / 6 / 7;
      }

      &:nth-of-type(9) {
        grid-area: 6 / 1 / 7 / 4;
      }

      &:nth-of-type(10) {
        grid-area: 6 / 4 / 7 / 7;
      }
    }
  }

  &__mobile {
    display: none;
    margin-top: 60px;

    @media screen and (max-width: $tablet-sm) {
      display: block;
    }
  }
}
