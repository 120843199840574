@import '../../styles/helpers/index.scss';

.page-header {
  padding: 35px 0;
  background-color: map-get($colors, 'background');

  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  transition: box-shadow 0.3s ease;

  @media screen and (max-width: $tablet-sm) {
    padding: 20px 0;
  }

  &.shadow {
    box-shadow: 0px 5px 15px 0px rgba(#000, 0.5);
  }

  &__inner {
    @include align-center;
    justify-content: space-between;

    @media screen and (max-width: $tablet-sm) {
      position: relative;
    }
  }

  .logo {
    @media screen and (max-width: $tablet-sm) {
      height: 30px;
    }
  }

  .navigation {
    @media screen and (max-width: $tablet-sm) {
      display: none;
    }

    ul {
      @include align-center;
      gap: 30px;
      text-transform: uppercase;

      font-weight: 700;
      font-size: 18px;
      line-height: 125%;

      a {
        color: map-get($colors, 'text');
        cursor: pointer;
      }
    }
  }

  .buttons {
    @include align-center;
    gap: 20px;

    @media screen and (max-width: $tablet-sm) {
      gap: 15px;
    }
  }

  .login-btn {
    padding-right: 35px;
    position: relative;
    display: block;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;

    color: map-get($colors, 'text');

    transition: color 0.3s ease;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }

    &::before {
      content: '';
      background-image: url('../../static/login-icon.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      width: 28px;
      height: 30px;

      position: absolute;
      top: 50%;
      right: 0;

      transform: translateY(-50%);

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    &:hover {
      color: map-get($colors, 'green');
    }

    &.mobile {
      display: none;

      padding: 8px 12px;
      font-size: 16px;

      background-color: map-get($colors, 'yellow');
      border-radius: 20px;

      @media screen and (max-width: $tablet-sm) {
        display: block;
      }
    }
  }

  .yellow-btn {
    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }

  .phone {
    padding-left: 30px;
    position: relative;
    display: block;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;

    color: map-get($colors, 'text');

    transition: color 0.3s ease;

    @media screen and (max-width: $tablet-sm) {
      font-size: 16px;
    }

    &::before {
      content: '';
      background-image: url('../../static/phone-icon.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      width: 25px;
      height: 25px;

      position: absolute;
      top: 50%;
      left: 0;

      transform: translateY(-50%);
    }

    &:hover {
      color: map-get($colors, 'green');
    }
  }
}
