@import '../../styles/helpers/index.scss';

.reviews {
  &__inner {
    display: flex;
    gap: 40px;

    @media screen and (max-width: $tablet-sm) {
      flex-direction: column;
    }

    .section-title {
      max-width: 30%;
      width: 100%;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
      }
    }
  }

  &__list {
    max-width: 70%;

    @media screen and (max-width: $tablet-sm) {
      max-width: unset;
      margin-top: 30px;
    }
  }

  .review-card {
    position: relative;
    max-width: 350px;
    margin: 30px 15px 0;
    padding: 70px 40px 50px;
    background-color: #fff;
    border: 1px solid #999;
    border-radius: 40px;
    color: map-get($colors, 'text');

    @media screen and (max-width: $tablet-sm) {
      max-width: unset;
      padding: 50px 20px 0;
      margin: 30px 0 0;
    }

    .stars {
      position: absolute;
      top: -30px;

      border: 4px solid map-get($colors, 'green');
      border-radius: 100px;
      background-color: #fff;
      height: 60px;
      padding: 0 20px;

      @include align-center;
    }

    .name {
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
    }

    .city {
      margin: 15px 0 0;
      font-size: 18px;
      line-height: 150%;
    }

    .text {
      margin: 15px 0 0;
      font-size: 18px;
      line-height: 150%;
      height: 300px;
      overflow: scroll;
    }
  }
}

.slick-slider {
  .slick-next,
  .slick-prev {
    width: 60px;
    height: 60px;

    background: #fff;
    border: 1px solid map-get($colors, 'green');
    border-radius: 50%;

    @include align-center;
    justify-content: center;

    position: absolute;
    right: 0;
    top: -80px;

    cursor: pointer;

    &::before {
      content: '';
      width: 33px;
      height: 16px;

      background-image: url('https://bigcityhomeservice.com/wp-content/uploads/2022/08/Arrow-2.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    @media screen and (max-width: $tablet-sm) {
      top: -30px;
      width: 45px;
      height: 45px;
    }
  }

  .slick-next {
    right: 20px;

    @media screen and (max-width: $tablet-sm) {
      right: 0;
    }
  }

  .slick-prev {
    right: 100px;
    left: unset;

    &::before {
      transform: translate(-50%, -50%) rotate(180deg);
    }

    @media screen and (max-width: $tablet-sm) {
      right: auto;
      left: 0;
    }
  }
}
