@import '../../styles/helpers/index.scss';

.call-to-action {
  &__inner {
    position: relative;
    padding: 70px 40px;
    border-radius: 40px;
    background-color: map-get($colors, 'green');
    color: #fff;

    @media screen and (max-width: $tablet-sm) {
      padding: 50px 20px;
      text-align: center;
    }

    &::before {
      content: '';

      background-image: url('../../static/cta-decor.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      height: 300px;
      width: 450px;

      position: absolute;
      right: 0;
      bottom: 0;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    h3 {
      font-weight: 700;
      font-size: 56px;
      line-height: 76px;

      @media screen and (max-width: $tablet-sm) {
        font-size: 32px;
        line-height: 1.24;
      }
    }

    p {
      max-width: 700px;
      margin: 15px 0 0;
      font-size: 24px;
      line-height: 150%;

      @media screen and (max-width: $tablet-sm) {
        max-width: unset;
        font-size: 18px;
      }
    }

    .links {
      margin: 45px 0 0;
      @include align-center;
      gap: 55px;

      @media screen and (max-width: $tablet-sm) {
        flex-direction: column;
        gap: 15px;
      }

      span {
        font-weight: 700;
        font-size: 24px;
        line-height: 200%;

        a {
          color: #fff;

          &:hover {
            text-decoration: underline !important;
          }
        }
      }
    }
  }
}
